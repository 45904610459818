import '../styles/main.scss';

document.addEventListener('DOMContentLoaded', () => {
  // underscored.forEach(element => {
  //   element.innerHTML = element.innerHTML.trim().replace(/\s+/gi, ' ').replace(/\s/gi, '<span>&#8203;_&#8203;</span>').replace(/<br>/gi, '<pre>\t\t</pre>');
  // });

  document.querySelectorAll('.underscored').forEach(element => {
    const underscored = element.innerHTML
      .trim()
      .replace(/[ \t\r]+/g, '<ins>_<wbr></ins>');
    const splitted = `<samp>${underscored
      .split('\n')
      .filter(Boolean)
      .join('</samp>\n<samp>')}</samp>`;

    element.innerHTML = splitted;

    // element.innerHTML = "<span class='line'>"+(element.textContent.split("\n").filter(Boolean).join("</span>\n<span class='line'>"))+"</span>";
  });

  const $menuToggle = document.querySelector('menu .toggle');
  const $nav = document.querySelector('nav');

  if ($nav && $menuToggle) {
    const $header = $menuToggle.closest('header');
    const updateToggle = () => {
      $menuToggle.innerText = $header.classList.contains('opened')
        ? 'Close'
        : 'Menu';
    };

    $menuToggle.addEventListener('click', event => {
      event.preventDefault();

      $header.classList.toggle('opened');
      updateToggle();
    });

    $nav.addEventListener('click', () => {
      $header.classList.toggle('opened', false);
      updateToggle();
    });
  }
});
